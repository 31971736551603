import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as Config } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ItemTypeDTO } from '../items.model';

@Injectable({ providedIn: 'root' })
export class ItemTypeService {

  readonly #http = inject(HttpClient);
  readonly #baseUrlPath = `${Config.apiBaseUrl}/item-type`;

  public getAll (): Observable<ItemTypeDTO[]> {
    return this.#http.get<ItemTypeDTO[]>(`${this.#baseUrlPath}`);
  }

  public getById (id: number): Observable<ItemTypeDTO> {
    return this.#http.get<ItemTypeDTO>(`${this.#baseUrlPath}/${id}`);
  }

  public create (item: Partial<ItemTypeDTO>): Observable<ItemTypeDTO> {
    return this.#http.post<ItemTypeDTO>(`${this.#baseUrlPath}`, item);
  }

  public update (item: ItemTypeDTO): Observable<ItemTypeDTO> {
    return this.#http.patch<ItemTypeDTO>(`${this.#baseUrlPath}/${item.id}`, item);
  }

  public delete (id: number): Observable<void> {
    return this.#http.delete<void>(`${this.#baseUrlPath}/${id}`);
  }

  public exists (code: string): Observable<boolean> {
    return this.#http.get<boolean>(`${this.#baseUrlPath}/exists`, { params: new HttpParams().appendAll({ code }) });
  }

}
